import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// layouts
import CompactLayout from 'src/layouts/compact';
import MainLayout from 'src/layouts/main';
// components
import { AuthGuard } from 'src/auth/guard';
import { SplashScreen } from 'src/components/loading-screen';
import SimpleLayout from 'src/layouts/simple/layout';

// ----------------------------------------------------------------------


const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));

const AuthredirectPage = lazy(() => import('src/pages/authredirect'));
const VerifiedByIntegraPage = lazy(() => import('src/pages/verifiedbyintegra'));

// ----------------------------------------------------------------------
const SigningKeyViewPage = lazy(() => import('src/pages/dashboard/signingkey'));
const VerifyDocumentPage = lazy(() => import('src/pages/verify'));
const VerifyWellsPage = lazy(() => import('src/pages/verify_wells'));
const VerifyIdentityPage = lazy(() => import('src/pages/verify_identity'));
const SigningCeremonyPage = lazy(() => import('src/pages/dashboard/signingceremony'));
const IntelNewContractPage = lazy(() => import('src/pages/intel/new-contract'));

const StripeView = lazy(() => import('src/sections/stripe'));
const Demo = lazy(() => import('../../sections/stripe/demo'));

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [

      { path: 'authredirect', element: <AuthredirectPage /> },
      { path: 'complete', element: <Demo /> },

    
    ],
  },
  {
    element: (
      <SimpleLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </SimpleLayout>
    ),
    children: [
    
      { path: 'verify', element: <VerifyDocumentPage /> },
      { path: 'verify_wells', element: <VerifyWellsPage /> },
      { path: 'verify_identity', element: <VerifyIdentityPage /> },
      { path: 'signing_key', element: <SigningKeyViewPage /> },
      { path: 'signing_ceremony', element: <SigningCeremonyPage /> },
      { path: 'transfer', element: <SigningKeyViewPage /> },
      { path: 'verifiedbyintegra', element: <VerifiedByIntegraPage /> },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
  {
    element: (
      <AuthGuard>
        <MainLayout expand>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'intel',
        children: [
          { element: <IntelNewContractPage />, index: true },
          { path: 'new-contract', element: <IntelNewContractPage /> },
        ],
      },
    
    ],
  },
];
