import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { element } from 'prop-types';
// import CryptoPaymentsView from '../../pages/dashboard/cryptoPayments';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const CreateSmartDocPage = lazy(() => import('src/pages/dashboard/createsmartdoc'));
const TransferSmartDocPage = lazy(() => import('src/pages/dashboard/transfersmartdoc'));
const ReceiveSmartDocPage = lazy(() => import('src/pages/dashboard/receivesmartdoc'));
const VerifySmartDocPage = lazy(() => import('src/pages/dashboard/verifysmartdoc'));
const ImportSmartDocPage = lazy(() => import('src/pages/dashboard/importsmartdoc'));
const AttachDocumentPage = lazy(() => import('src/pages/dashboard/attach-document'));

const ChannelsPage = lazy(() => import('src/pages/dashboard/channels'));
const GetSigningKeyPage = lazy(() => import('src/pages/dashboard/signingkey'));


const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));



const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
 

// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));



// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));


const MyAppsPage = lazy(() => import('src/pages/dashboard/myapps'));
const AppDetailsPage = lazy(() => import('src/pages/dashboard/apps/details'));
const MyAppDetailsPage = lazy(() => import('src/pages/dashboard/myapps/details'));
const ContactsPage = lazy(() => import('src/pages/dashboard/contacts'));

const DocuSign = lazy(() => import('src/pages/dashboard/apps/DocuSign'));
const AccountAddOrganization = lazy(() => import('src/sections/organization/organization-form'));


const AdminCreateFormsPage = lazy(() => import('src/pages/admin/forms/createForms'));
const AdminCreateJSONFormsPage = lazy(() => import('src/pages/admin/forms/createJSONForms'));
const AdminGeneratorPage = lazy(() => import('src/pages/admin/forms/generator'));

const MemberPage = lazy(() => import('src/pages/dashboard/member'));
const OrganizationViewPage = lazy(() => import('src/pages/dashboard/organization/view'));
const OrganizationListPage = lazy(() => import('src/pages/dashboard/organization/list'));
const OrganizationNewPage = lazy(() => import('src/pages/dashboard/organization/new'));
const OrganizationEditPage = lazy(() => import('src/pages/dashboard/organization/edit'));
const OrganizationVerifyPage = lazy(() => import('src/pages/dashboard/organization/verify'));
const OrganizationTemplatesListPage = lazy(() =>
  import('src/pages/dashboard/organization/templates-list')
);
const OrganizationTemplatesEditPage = lazy(() =>
  import('src/pages/dashboard/organization/templates-edit')
);
const UserVerifyPage = lazy(() => import('src/pages/dashboard/user/verify'));

const SmartDocRegistrationPage = lazy(() =>
  import('src/pages/dashboard/smart-doc-registration-page')
);
const SmartDocActionPage = lazy(() => import('src/pages/dashboard/smart-doc-action-page'));
const ConfirmationPage = lazy(() => import('../../sections/confirmationPage'));
const ResultPageView = lazy(() => import('../../sections/resultPageView'));
const TokenizedOwnerListView = lazy(() => import('../../sections/tokenized-owner'));
const ImportDocDetailsView = lazy(() => import('../../sections/importDocDetails'));
const OrgAdmins = lazy(() => import('../../sections/orgAdmins'));
const ForgotPassword = lazy(() => import('src/pages/auth/firebase/forgot-password'));


const ESignaturePage = lazy(() => import('src/pages/dashboard/e-signature'));
const ArchivedDocumentsPage = lazy(() => import('src/pages/dashboard/archived-documents'));
const JobEdit = lazy(() => import('src/sections/myapps/view/job-edit'));
const GeneralMarketplaceEmbed = lazy(() => import('src/pages/general-marketplace'));
const IntegrationAppPage = lazy(() => import('src/pages/integration-app-embed'));
const VimeoPlayer = lazy(() => import('src/pages/vimeo/vimeo-view'));
const TransfertokenPage = lazy(() => import('src/pages/tokenizedTransferView'));

const CryptoPaymentsView = lazy(() => import('src/pages/dashboard/cryptoPayments'));
const StripeView = lazy(() => import('src/sections/stripe'));
const Complete = lazy(() => import('../../sections/stripe/completePage'));

const PreviewTemplate = lazy(() =>
  import('../../components/smart-doc-registration-forms/previewTemplate')
);

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'createsmartdoc', element: <CreateSmartDocPage /> },
      { path: 'documentdetails', element: <ImportDocDetailsView /> },
      { path: 'transfersmartdoc', element: <TransferSmartDocPage /> },
      { path: 'receivesmartdoc', element: <ReceiveSmartDocPage /> },
      { path: 'verifysmartdoc', element: <VerifySmartDocPage /> },
      { path: 'importsmartdoc', element: <ImportSmartDocPage /> },
      { path: 'signingkey', element: <GetSigningKeyPage /> },
      { path: 'channels', element: <ChannelsPage /> },

      { path: 'file', element: <OverviewFilePage /> },

      { path: 'attach_document', element: <AttachDocumentPage /> },
      {
        path: 'myapps',
        children: [
          { element: <MyAppsPage />, index: true },
          { path: ':id', element: <MyAppDetailsPage /> },
          { path: 'detail/:id', element: <JobEdit /> },
          { path: 'config/:id', element: <JobEdit /> },
        ],
      },
      { path: 'contacts', element: <ContactsPage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
          { path: 'verify', element: <UserVerifyPage /> },
        ],
      },
    
  
      { path: 'file-manager', element: <FileManagerPage /> },
 
      { path: 'chat', element: <ChatPage /> },

      { path: 'permission', element: <PermissionDeniedPage /> },

     

      {
        path: 'org',
        children: [
          { element: <OrganizationViewPage />, index: true },
          { path: 'list', element: <OrganizationListPage /> },
          { path: 'new', element: <OrganizationNewPage /> },
          { path: ':id', element: <OrganizationEditPage /> },
          { path: 'verify', element: <OrganizationVerifyPage /> },
          { path: 'templates', element: <OrganizationTemplatesListPage /> },
          { path: 'templates/:id', element: <OrganizationTemplatesEditPage /> },
        ],
      },
      {
        path: 'members',
        children: [
          { element: <MemberPage />, index: true },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id', element: <UserEditPage /> },
        ],
      },
     
      {
        path: 'forms',
        children: [
          {
            path: 'create',
            element: <AdminCreateFormsPage />,
          },
          {
            path: 'json-create',
            element: <AdminCreateJSONFormsPage />,
          },
          {
            path: 'generate',
            element: <AdminGeneratorPage />,
          },
        ],
      },

      { path: 'registration', element: <SmartDocRegistrationPage /> },
      { path: 'file-action', element: <SmartDocActionPage /> },
      { path: 'confirmation', element: <ConfirmationPage /> },
      { path: 'resultview', element: <ResultPageView /> },
      { path: 'orgadmins', element: <OrgAdmins /> },
      { path: 'forgotpassword', element: <ForgotPassword /> },
      { path: 'tokenizedowner', element: <TokenizedOwnerListView /> },
      {
        path: 'intel',
        children: [
          { element: <MyAppDetailsPage intel />, index: true },
          { path: 'config', element: <JobEdit intel /> },
        ],
      },
      { path: 'e-signature', element: <ESignaturePage /> },
      { path: 'archived-documents', element: <ArchivedDocumentsPage /> },
      { path: 'general-marketplace', element: <GeneralMarketplaceEmbed /> },
      { path: 'integration-app', element: <IntegrationAppPage /> },
      { path: 'vimeo-player', element: <VimeoPlayer /> },
      { path: 'transfer-token', element: <TransfertokenPage /> },

      { path: 'crypto-payments', element: <CryptoPaymentsView /> },
      { path: 'payment', element: <StripeView /> },
      { path: 'preview-template', element: <PreviewTemplate /> },

    ],
  },
];
