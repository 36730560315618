// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// carousel
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// locales
import { LocalizationProvider } from 'src/locales';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import { MotionLazy } from 'src/components/animate/motion-lazy';
import ProgressBar from 'src/components/progress-bar';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
// sections

// auth
import { AuthConsumer, AuthProvider } from 'src/auth/context/jwt';
// import { Helmet } from 'react-helmet-async';
// import { AuthProvider as OIDCAuthProvider } from 'react-oidc-context';
import { INTERCOM_API_BASE, INTERCOM_APP_ID } from 'src/config-globals';
import AdminContextProvider from './store/adminContext';
import ContentContextProvider from './store/contentContext';
// import { SocketProvider } from './store/socketContext';

import ClioContextProvider from './store/clioContext';
import { SocketProvider } from './store/socketContext';
import UserContextProvider from './store/userContext';
import PlatformContextProvider from './store/platformContext';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/auth0';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/amplify';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/firebase';

// ----------------------------------------------------------------------

export default function App() {
 

  useScrollToTop();

  window.Intercom('boot', {
    api_base: INTERCOM_API_BASE,
    app_id: INTERCOM_APP_ID,
  });

  return (
    // <OIDCAuthProvider {...oidcConfig}>
    <AuthProvider>
      <SocketProvider>
        <UserContextProvider>
          <ContentContextProvider>
            <AdminContextProvider>
              <LocalizationProvider>
                <SettingsProvider
                  defaultSettings={{
                    themeMode: 'light', // 'light' | 'dark'
                    themeDirection: 'ltr', //  'rtl' | 'ltr'
                    themeContrast: 'default', // 'default' | 'bold'
                    themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                    themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                    themeStretch: false,
                  }}
                >
                  <ThemeProvider>
                    <MotionLazy>
                      <SnackbarProvider>
                       
                          <SettingsDrawer />
                          <ProgressBar />
                          <AuthConsumer>
                       
                              <PlatformContextProvider>
                                <Router />
                              </PlatformContextProvider>
                          </AuthConsumer>
                      
                      </SnackbarProvider>
                    </MotionLazy>
                  </ThemeProvider>
                </SettingsProvider>
              </LocalizationProvider>
            </AdminContextProvider>
          </ContentContextProvider>
        </UserContextProvider>
      </SocketProvider>
    </AuthProvider>
    // </OIDCAuthProvider>
  );
}
