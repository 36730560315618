import { paths } from 'src/routes/paths';

// Access build-time environment variables first, then runtime configuration
const runtimeConfig = window.runtimeConfig || {};

// NOTE - Open this when pushing to main/develop branch
// export const SWITCHBOARD_AUTH_HOST_API =
//   runtimeConfig.REACT_APP_SWITCHBOARD_AUTH_HOST_API ||
//   process.env.REACT_APP_SWITCHBOARD_AUTH_HOST_API;

// NOTE - Open this when developing in localhost
export const SWITCHBOARD_AUTH_HOST_API =
  process.env.REACT_APP_SWITCHBOARD_AUTH_HOST_API ||
  runtimeConfig.REACT_APP_SWITCHBOARD_AUTH_HOST_API;

// NOTE - Open this when pushing to main/develop branch
// export const FRONTEND_SERVER_BASE_URL =
//   runtimeConfig.REACT_APP_FRONTEND_SERVER_BASE_URL ||
//   process.env.REACT_APP_FRONTEND_SERVER_BASE_URL;

// NOTE - Open this when developing in localhost
export const FRONTEND_SERVER_BASE_URL =
  process.env.REACT_APP_FRONTEND_SERVER_BASE_URL ||
  runtimeConfig.REACT_APP_FRONTEND_SERVER_BASE_URL;

export const PATH_FOR_FORM_GENERATOR = paths.dashboard.forms.generate;

export const PATH_AFTER_LOGIN = '/dashboard';

export const SWITCHBOARD_FILE_PATH = '';

export const FRONTEND_VERSION = '1.0.29';

export const MAPBOX_API =
  process.env.REACT_APP_MAPBOX_API || runtimeConfig.REACT_APP_MAPBOX_API || 'default_mapbox_api';

export const ASSETS_API =
  'https://comms-dev.integraledger.com';
// runtimeConfig.REACT_APP_ASSETS_API || process.env.REACT_APP_ASSETS_API || 'default_assets_api';
export const FIREBASE_API = '';

export const SHARED_SWITCHBOARD =
  (process.env.REACT_APP_IS_SHARED_SWITCHBOARD || runtimeConfig.REACT_APP_IS_SHARED_SWITCHBOARD) ===
  'true';

// export const SHARED_SWITCHBOARD =
//   (runtimeConfig.REACT_APP_IS_SHARED_SWITCHBOARD || process.env.REACT_APP_IS_SHARED_SWITCHBOARD) ===
//   'true';

// export const INTERCOM_API_BASE =
//   runtimeConfig.REACT_APP_INTERCOM_API_BASE || process.env.REACT_APP_INTERCOM_API_BASE;

export const INTERCOM_API_BASE =
  process.env.REACT_APP_INTERCOM_API_BASE || runtimeConfig.REACT_APP_INTERCOM_API_BASE;

// export const INTERCOM_APP_ID =
//   runtimeConfig.REACT_APP_INTERCOM_APP_ID || process.env.REACT_APP_INTERCOM_APP_ID;

export const INTERCOM_APP_ID =
  process.env.REACT_APP_INTERCOM_APP_ID || runtimeConfig.REACT_APP_INTERCOM_APP_ID;

// export const CONNECT_ENDPOINT =
//   runtimeConfig.REACT_APP_STATIC_ORG_URL || process.env.REACT_APP_STATIC_ORG_URL;

export const CONNECT_ENDPOINT =
  process.env.REACT_APP_STATIC_ORG_URL || runtimeConfig.REACT_APP_STATIC_ORG_URL;

// export const OIDC_CONFIG = {
//   AUTHORITY: process.env.REACT_APP_OIDC_AUTHORITY || runtimeConfig.REACT_APP_OIDC_AUTHORITY,
//   CLIENT_ID: process.env.REACT_APP_OIDC_CLIENT_ID || runtimeConfig.REACT_APP_OIDC_CLIENT_ID,
//   REDIRECT_URI:
//     process.env.REACT_APP_OIDC_REDIRECT_URI || runtimeConfig.REACT_APP_OIDC_REDIRECT_URI,
// };

export const OIDC_CONFIG = {
  AUTHORITY: '4c387fa1-9945-45e5-88ee-93a4d13548bb',
  CLIENT_ID: '5e612b85-eb11-44d8-b7cb-c4993cc83a01',
  REDIRECT_URI: `${FRONTEND_SERVER_BASE_URL}/auth/sso`,
};

// export const CHILKAT_ENDPOINT =
//   runtimeConfig.REACT_APP_CHILKAT_ENDPOINT || process.env.REACT_APP_CHILKAT_ENDPOINT;

export const CHILKAT_ENDPOINT =
  process.env.REACT_APP_CHILKAT_ENDPOINT || runtimeConfig.REACT_APP_CHILKAT_ENDPOINT;

// export const PAYMENT_ENDPOINT =
//   runtimeConfig.REACT_APP_PAYMENT_ENDPOINT || process.env.REACT_APP_PAYMENT_ENDPOINT;

export const PAYMENT_ENDPOINT =
  process.env.REACT_APP_PAYMENT_ENDPOINT || runtimeConfig.REACT_APP_PAYMENT_ENDPOINT;

// export const PAYMENT_FRONTEND_URL =
//   runtimeConfig.REACT_APP_PAYMENT_FRONTEND_URL || process.env.REACT_APP_PAYMENT_FRONTEND_URL;

export const PAYMENT_FRONTEND_URL =
  process.env.REACT_APP_PAYMENT_FRONTEND_URL || runtimeConfig.REACT_APP_PAYMENT_FRONTEND_URL;



  export const REACT_APP_BACKEND_NEW_URL = 'https://schema.integraledger.com/'